export default [
    {
        title: 'nav_horizontal.my_appeals',
        isLaIcon: true,
        laIcon: 'la-comments',
        action: 'list',
        resource: 'appeal',
        route: 'appeal-list',
    },
]
