import utilsService from '@/services/utils/utils.service'
let forms = utilsService.getListFormulaires()

forms = forms.map((e,i) => {
  const temp = JSON.parse(JSON.stringify(e))
  temp.action = 'read'
  temp.resource = e.resource || ''
  // if(i===3) temp.action='read'
  // change route according to role
  // temp.route = getRouteByRole()
  return temp
})
// console.log('forms:::+++++++++++++ 🔴🟢', forms)

export default [
  {
    title: "Mes demandes",
    icon: 'BookIcon',
    isLaIcon: true,
    laIcon: 'la-folder-open',
    action: 'read',  
    resource: 'Public',
    route: 'formulaires-list-ue'
    // children: [
    //   ...forms,
    //   {
    //     title: 'Notifications diverses',
    //     route: 'config-parametres',
    //     icon: 'UsersIcon',
    //     action: 'read',  
    //     resource: 'Public',
    //   },
    // ],
  },
]
