<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-end dropdown-user-link"
  >
    <template #button-content>
      <div class="d-flex align-items-center">
        <div
          v-if="userData"
          class="user-nav"
        >
          <p class="user-name font-weight-bolder mb-0">
            {{
              ((userData && userData.firstname) || "") +
                " " +
                ((userData && userData.lastname) || "")
            }}
          </p>

          <span class="d-flex flex-row">
            <span
              v-for="(role, indexR) in userData.roles"
              v-if="isAdministrationUser"
              :key="indexR"
              class=""
            >
              <span v-if="indexR > 0"> &nbsp; | </span>
              {{ role.title }}
            </span>
          </span>

          <span v-if="userData && userData.profile">
            Établissement:
            <strong>{{
              (userData && userData.profile.nom_etablissement) || ""
            }}</strong>
          </span>
          <span
            v-if="userData && userData.identity"
            v-b-tooltip.hover.bottom="
              'Lors de chaque nouvelle demande, il vous sera exigé de fournir cet identifiant, qui servira à votre identification et au suivi de votre demande.'
            "
            class="small"
          >
            Identifiant:

            <span @click.stop="copyToClipboard(userData.identity)">
              <strong>{{ userData.identity }}</strong>
            </span>
          </span>
          <span class="small" />
        </div>

        <b-avatar
          size="40"
          :src="userData && userData.avatar"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <!-- <feather-icon
          v-if="!userData || !userData.fullName"
          icon="UserIcon"
          size="30"
        /> -->
        </b-avatar>
      </div>
    </template>

    <!-- <b-dropdown-item>
      <span> {{ $can("create", "manuel_utilisation") }}</span>
    </b-dropdown-item> -->

    <b-dropdown-item
      :to="{ name: 'users-profile' }"
      link-class="d-flex align-items-center"
    >
      <i
        class="las la-address-card mr-50"
        size="16"
      />
      <span>{{ $t("user_dropdown.profile") }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="$can('list', 'manuel_utilisation')"
      :to="{ name: 'manuel-utilisation-list' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="BookIcon"
        class="mr-50"
      />
      <span>{{ $t("user_dropdown.user_manual") }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{ $t("user_dropdown.logout") }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  VBTooltip,
} from 'bootstrap-vue'
import {
  mapState, mapMutations, mapGetters, mapActions,
} from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'
import localstorageService from '@/services/localstorage/localstorage.service'
import utilsService from '@/services/utils/utils.service'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    ToastificationContent
  },
  computed: {
    ...mapState('auth', {
      isAuthenticated: 'isAuthenticated',
    }),
    // -----------------------------------
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    const { isOperator, isAdministrationUser } = utilsService.currentUserUtils()

    return {
      userData: localstorageService.getUserData(),
      avatarText,
      // etablissementDetails: null,
      commissariatDetails: null,
      isOperator,
      isAdministrationUser,
    }
  },
  mounted() {
    // const {
    //   userData,
    //   isAdministrationUser
    // } = utilsService.currentUserUtils()
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>
.non-clickable {
  user-select: text; /* Permet la sélection de texte */
  strong {
    cursor: text !important; /* Change le curseur pour indiquer qu'il s'agit d'un texte copiable */
  }
}
</style>
