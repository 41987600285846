export default [
  {
    title: "nav_horizontal.home",
    isLaIcon: true,
    laIcon: "la-home",
    route: "home-public",
    action: "read",
    resource: "Public",
  },
  {
    title: "nav_horizontal.numbers_trends",
    action: "read",
    resource: "Public",
    isLaIcon: true,
    laIcon: "la-chart-bar",
    children: [
      {
        title: "sectorsList.hebergement",
        route: "blog-chiffres-tendances-hebergement",
        action: "read",
        isLaIcon: true,
        laIcon: "la-building",
        resource: "Public",
      },
      {
        title: "sectorsList.guideTouristique",
        route: "blog-chiffres-tendances-guide",
        isLaIcon: true,
        laIcon: "la-map-marked-alt",
        action: "read",
        resource: "Public",
      },
      {
        title: "sectorsList.agenceVoyage",
        route: "blog-chiffres-tendances-agence",
        action: "read",
        isLaIcon: true,
        laIcon: "la-plane",
        resource: "Public",
      },
      {
        title: "sectorsList.restaurant",
        route: "blog-chiffres-tendances-restaurant",
        isLaIcon: true,
        laIcon: "la-utensils",
        action: "read",
        resource: "Public",
      },
    ],
  },
  {
    title: "nav_horizontal.regulations",
    action: "read",
    resource: "Public",
    isLaIcon: true,
    laIcon: "la-newspaper",
    children: [
      {
        title: "sectorsList.hebergement",
        route: "blog-reglementations-hebergement",
        action: "read",
        isLaIcon: true,
        laIcon: "la-building",
        resource: "Public",
      },
      {
        title: "sectorsList.guideTouristique",
        route: "blog-reglementations-guide",
        isLaIcon: true,
        laIcon: "la-map-marked-alt",
        action: "read",
        resource: "Public",
      },
      {
        title: "sectorsList.agenceVoyage",
        route: "blog-reglementations-agence",
        action: "read",
        isLaIcon: true,
        laIcon: "la-plane",
        resource: "Public",
      },
      {
        title: "sectorsList.restaurant",
        route: "blog-reglementations-restaurant",
        isLaIcon: true,
        laIcon: "la-utensils",
        action: "read",
        resource: "Public",
      },
    ],
  },
  // {
  //   title: 'Répertoire des opérateurs touristiques',
  //   action: 'read',
  //   resource: 'Public',
  //   children: [
  //     {
  //       title: 'Hébergement touristique',
  //       route: 'config-permissions',
  //       action: 'read',
  //       resource: 'Public',
  //     },
  //     {
  //       title: 'Agence de voyage',
  //       route: 'config-permissions',
  //       action: 'read',
  //       resource: 'Public',
  //     },
  //     {
  //       title: 'Guides touristiques',
  //       route: 'config-permissions',
  //       action: 'read',
  //       resource: 'Public',
  //     },
  //     {
  //       title: 'Restaurants et assimilés',
  //       route: 'config-permissions',
  //       action: 'read',
  //       resource: 'Public',
  //     },
  //   ],
  // },
  {
    title: "nav_horizontal.tourismOperators",
    // title: 'Répertoire des opérateurs touristiques',
    isLaIcon: true,
    laIcon: "la-building",
    route: "repertoire-operateurs-touristiques",
    action: "read",
    resource: "Public",
  },
  {
    title: "nav_horizontal.investors_section",
    isLaIcon: true,
    laIcon: "la-user-tie",
    route: "espace-investisseur",
    action: "read",
    resource: "Public",
  },
  {
    title: "nav_horizontal.claims_not_auth",
    isLaIcon: true,
    laIcon: "la-envelope-open",
    route: "depot-reclamation",
    action: "read",
    resource: "Public",
  },
];
