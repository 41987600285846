<template>
  <div>
    <b-nav-item-dropdown
      id="dropdown-grouped"
      variant="link"
      class="dropdown-language"
      right
    >
      <template #button-content>
        <b-img
          :src="currentLocale.img"
          height="14px"
          width="22px"
          :alt="currentLocale.locale"
        /> 
        <span class="ml-50 text-body d-none d-sm-inline">{{ currentLocale.name }}</span>
      </template>
      <b-dropdown-item
        v-for="localeObj in locales"
        :key="localeObj.locale"
        @click="
          () => {
            $i18n.locale = localeObj.locale;
            setLocalLanguage(localeObj.locale);
          }
        "
      >
        <b-img
          :src="localeObj.img"
          height="14px"
          width="22px"
          :alt="localeObj.locale"
        />
        <span class="ml-50">{{ localeObj.name }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import localstorageService from '@/services/localstorage/localstorage.service'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      if(localstorageService.getLocalLanguage()) this.$i18n.locale = localstorageService.getLocalLanguage()
      return this.locales.find((l )=> {
        return l.locale === this.$i18n.locale
      })
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'fr',
        img: require('@/assets/images/flags/fr.png'),
        name: 'Français',
      },
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },
  methods: {
    setLocalLanguage(language){
      localstorageService.setLocalLanguage(language)
    }
  }
}
</script>

<style></style>
