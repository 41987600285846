export default [
  {
    title: "nav_horizontal.auto_evaluations",
    isLaIcon: true,
    laIcon: "la-clipboard",
    action: "access",
    resource: "auto_evaluation",
    route: "auto-evaluation-list",
  },
];
