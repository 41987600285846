import utilsService from "@/services/utils/utils.service";

const { faqDetail } = utilsService.currentUserUtils();
const faqId = (faqDetail && faqDetail._id) || "";

export default [
  {
    title: "nav_horizontal.outils_intern.title",
    icon: "BookIcon",
    isLaIcon: true,
    laIcon: "la-toolbox",
    action: "access",
    resource: "demandes",
    children: [
      {
        title: "nav_horizontal.outils_intern.bylaws",
        isLaIcon: true,
        laIcon: "la-clipboard",
        action: "access",
        resource: "arrete",
        route: "arretes-list",
      },
      {
        title: "nav_horizontal.outils_intern.rdv",
        route: "list-rdv",
        isLaIcon: true,
        laIcon: "la-calendar",
        action: "access",
        resource: "demandes",
      },
    ],
  },
];
